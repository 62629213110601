import React, { Component } from 'react';
import Layout from '../components/layout';
import './404.scss';

class NotFoundPage extends Component {
  render() {
    let title = {
      english: 'Not Found',
      french: 'Not Found',
    };
    return (
      <Layout title={title}>
        <main className={'container content-wrapper not-found-page'}>
          <div className="page-content p-4 text-center">
            <p>It looks like nothing was found at this location.</p>
          </div>
        </main>
      </Layout>
    );
  }
}

export default NotFoundPage;
